import {
    IRequest,
    ISecondaryOrder,
    ISecondaryOrderBuyAcceptRequest,
    ISecondaryOrderBuyRequest,
    ISecondaryOrderSellAcceptRequest,
    ISecondaryOrderSellRequest,
    ISecondaryOrderShort,
    RevokeByOrderPayload,
    SignTransactionFullType,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem, ResponseItems } from '../../types/types'
import { path } from '../../utils'

export async function getSecondaryDfaDealsService<T>(): Promise<IRequest<ResponseItems<T>>> {
    return requestDecorator.call(
        this,
        this.paths.SecondaryDfaDeals,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

/**
 * @deprecated
 */
export async function getSecondaryDfaOrdersService(): Promise<
    IRequest<ResponseItems<ISecondaryOrder>>
> {
    return requestDecorator.call(
        this,
        this.paths.SecondaryDfaOrders,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function postSecondaryDfaSellOrderService(
    sellOrder: Partial<ISecondaryOrderSellRequest>,
): Promise<IRequest<{ transaction: SignTransactionFullType }>> {
    return requestDecorator.call(
        this,
        this.paths.SecondaryDfaOrderSell,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: sellOrder },
    )
}

export async function putSecondaryDfaSellOrderService(
  data: Partial<ISecondaryOrder> & { transaction: SignTransactionFullType },
): Promise<IRequest> {
  return requestDecorator.call(
    this,
    this.paths.SecondaryDfaOrderSell,
    HttpMethod.PUT,
    HttpCode.SUCCESS_200,
    { data },
  )
}

export async function postSecondaryDfaBuyOrderService(
    buyOrder: Partial<ISecondaryOrderBuyRequest>,
): Promise<IRequest<{ transaction: SignTransactionFullType }>> {
    return requestDecorator.call(
        this,
        this.paths.SecondaryDfaOrderBuy,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: buyOrder },
    )
}

export async function putSecondaryDfaBuyOrderService(
  buyOrder: Partial<ISecondaryOrder & { transaction: SignTransactionFullType }>,
): Promise<IRequest> {
  return requestDecorator.call(
    this,
    this.paths.SecondaryDfaOrderBuy,
    HttpMethod.PUT,
    HttpCode.SUCCESS_200,
    { data: buyOrder },
  )
}

export async function postSecondaryDfaSellOrderAcceptService(
    id: string,
    sellOrder: Partial<ISecondaryOrderSellAcceptRequest>,
): Promise<IRequest<{ transaction: SignTransactionFullType }>> {
    return requestDecorator.call(
        this,
        path(this.paths.SecondaryDfaOrderSellByOrderId, id),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: sellOrder },
    )
}

export async function putSecondaryDfaSellOrderAcceptService(
  data: Partial<ISecondaryOrderSellAcceptRequest> & { transaction: SignTransactionFullType },
): Promise<IRequest> {
  const { uuid, ...sellOrder } = data
  return requestDecorator.call(
    this,
    path(this.paths.SecondaryDfaOrderSellByOrderId, uuid),
    HttpMethod.PUT,
    HttpCode.SUCCESS_200,
    { data: sellOrder },
  )
}

export async function postSecondaryDfaBuyOrderAcceptService(
    id: string,
    buyOrder: Partial<ISecondaryOrderBuyAcceptRequest>,
): Promise<IRequest<{ transaction: SignTransactionFullType }>> {
    return requestDecorator.call(
        this,
        path(this.paths.SecondaryDfaOrderBuyByOrderId, id),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: buyOrder },
    )
}

export async function putSecondaryDfaBuyOrderAcceptService(
  data: Partial<ISecondaryOrderBuyAcceptRequest> & { transaction: SignTransactionFullType },
): Promise<IRequest> {
  const { uuid, ...buyOrder } = data
  return requestDecorator.call(
    this,
    path(this.paths.SecondaryDfaOrderBuyByOrderId, uuid),
    HttpMethod.PUT,
    HttpCode.SUCCESS_200,
    { data: buyOrder },
  )
}

export async function rejectSecondaryDfaBuyOrder(
    data: Partial<RevokeByOrderPayload>,
): Promise<IRequest<ResponseItem<ISecondaryOrderShort>>> {
    return requestDecorator.call(
        this,
        this.paths.SecondaryDfaOrderBuyReject,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function rejectSecondaryDfaSellOrder(
    data: Partial<RevokeByOrderPayload>,
): Promise<IRequest<ResponseItem<ISecondaryOrderShort>>> {
    return requestDecorator.call(
        this,
        this.paths.SecondaryDfaOrderSellReject,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function cancelSecondaryDfaBuyOrder(
    data: RevokeByOrderPayload,
): Promise<IRequest<ResponseItem<ISecondaryOrderShort>>> {
    return requestDecorator.call(
        this,
        this.paths.SecondaryDfaOrderBuyCancel,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function cancelSecondaryDfaSellOrder(
    data: RevokeByOrderPayload,
): Promise<IRequest<ResponseItem<ISecondaryOrderShort>>> {
    return requestDecorator.call(
        this,
        this.paths.SecondaryDfaOrderSellCancel,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}
